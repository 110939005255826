import { extendTheme } from "@chakra-ui/react";
import { CardComponent } from "./additions/card/card";
import { buttonStyles } from "./components/button";
import { badgeStyles } from "./components/badge";
import { inputStyles } from "./components/input";
import { progressStyles } from "./components/progress";
import { sliderStyles } from "./components/slider";
import { textareaStyles } from "./components/textarea";
import { linkStyles } from "./components/link";
import { modalStyles } from "./components/modal";
import { menuStyles } from "./components/menu";
import { alertStyles } from "./components/alert";
import { globalStyles } from "./styles";
export default extendTheme({
    sm: "320px",
    "2sm": "380px",
    md: "768px",
    lg: "960px",
    xl: "1200px",
    "2xl": "1600px",
    "3xl": "1920px",
    config: {
        initialColorMode: "system"
    }
}, globalStyles, badgeStyles, buttonStyles, linkStyles, progressStyles, sliderStyles, inputStyles, textareaStyles, CardComponent, modalStyles, menuStyles, alertStyles);
