import { mode } from "@chakra-ui/theme-tools";
export const modalStyles = {
    components: {
        Modal: {
            variants: {
                brand: (props)=>({
                        dialog: {
                            bg: mode("#ffffff", "navy.800")(props)
                        }
                    })
            }
        }
    }
};
