import { mode } from "@chakra-ui/theme-tools";
export const alertStyles = {
    components: {
        AlertDialog: {
            variants: {
                brand: (props)=>({
                        dialog: {
                            bg: mode("#ffffff", "navy.800")(props)
                        }
                    })
            }
        }
    }
};
