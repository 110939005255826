import { mode } from "@chakra-ui/theme-tools";
export const menuStyles = {
    components: {
        Menu: {
            variants: {
                brand: (props)=>({
                        list: {
                            bg: mode("#ffffff", "navy.800")(props)
                        },
                        item: {
                            bg: "transparent",
                            borderRadius: "lg",
                            _focus: {
                                bg: mode("brand.200", "brand.200")(props),
                                color: "white"
                            },
                            _active: {
                                bg: mode("brand.200", "brand.200")(props),
                                color: "white"
                            },
                            _disabled: {
                                color: mode("secondaryGray.900", "secondaryGray.300")(props)
                            },
                            _hover: {
                                bg: mode("brand.200", "brand.200")(props),
                                color: "white",
                                _disabled: {
                                    bg: mode("brand.200", "brand.200")(props)
                                }
                            }
                        }
                    })
            }
        }
    }
};
